<template>
  <div class="comp">
    <el-upload
      :action="url"
      list-type="picture-card"
      accept=".png,.jpg,.jpeg"
      :on-success="handleSuccess"
      :before-upload="handlebefore"
      :headers="header"
      :show-file-list="false"
    >
      <i slot="default" v-show="updisplay" class="el-icon-plus"></i>
      <hovermask>
        <img
          :src="imgurl"
          v-show="!updisplay"
          class="el-upload el-upload--picture-card"
        />
        <template v-slot:action>
          <i class="el-icon-refresh-left">重新上传</i>
        </template>
      </hovermask>
    </el-upload>
    <div class="tip">
      <p>照片要求：</p>
      <p>1.分辨率在1200*1600的2寸蓝底电子证件照；</p>
      <p>2.证件照文件大小请控制在500KB以内；</p>
      <p>3.文件名请以个人身份证号命名；</p>
      <p>
        4.身份证号带X的请输入大写X，
        <el-tooltip class="item" effect="light" placement="right-start">
          <div slot="content" class="tooltips">
            <img src="../assets/images/user/uploadfiledemo.png" alt="" />
          </div>
          <span>证件照文件举例。</span>
        </el-tooltip>
      </p>
    </div>
  </div>
</template>
<script>
import hovermask from './hovermask'
export default {
  props: ['fileUrl'],
  data() {
    return {
      url: '',
      file: '',
      header: {},
      dialogImageUrl: '',
      dialogVisible: false,
      disabled: false,
      updisplay: true,
      imgurl: '',
    }
  },
  components: {
    hovermask,
  },
  created() {
    this.url = process.env.VUE_APP_URL + 'upload/certPhoto'
    if (this.fileUrl || this.file.response) {
      this.imgurl =
        'http://api.wangpeishi.org.cn' +
          this.fileUrl +
          '?' +
          Math.ceil(Math.random() * 10000) ||
        'http://api.wangpeishi.org.cn' +
          this.file.response.data +
          '?' +
          Math.ceil(Math.random() * 10000)
    }
    this.header.Authorization = 'Bearer ' + this.Storage.getStorage('token')
    if (this.fileUrl != '' && this.fileUrl != null) {
      this.updisplay = false
    } else {
      this.updisplay = true
    }
  },
  methods: {
    handleSuccess(response, file, fileList) {
      console.log(response, file, fileList)
      this.$message.success('上传成功')
      this.imgurl = file.url
      this.file = file
      this.updisplay = false
      this.$emit('getPhoto', file)
    },
    handlebefore(file) {
      this.updisplay = false

      var filename = file.name.substring(0, file.name.lastIndexOf('.'))
      let _IDRe18 = /^([1-6][1-9]|50)\d{4}(18|19|20)\d{2}((0[1-9])|10|11|12)(([0-2][1-9])|10|20|30|31)\d{3}[0-9Xx]$/
      let _IDre15 = /^([1-6][1-9]|50)\d{4}\d{2}((0[1-9])|10|11|12)(([0-2][1-9])|10|20|30|31)\d{3}$/

      if (!(_IDRe18.test(filename) || _IDre15.test(filename))) {
        this.$message.error('文件名不符合规范')
        return false
      } else {
        if (isNaN(filename.charAt(filename.length - 1))) {
          if (!/[A-Z]/.test(filename.charAt(filename.length - 1))) {
            this.$message.error('文件名不符合规范')
            return false
          }
        }
      }
      var testmsg = file.name.substring(file.name.lastIndexOf('.') + 1)

      if (file.size / 1024 > 500) {
        this.$message.error('文件大小不能超过500k')
        return false
      } else {
        if (testmsg != 'png' && testmsg != 'jpg' && testmsg != 'jpeg') {
          this.$message.error('只能上传png，jpg，jpeg类型文件')
          return false
        }
      }
    },
  },
}
</script>
<style lang="less" scoped>
.comp {
  display: flex;
  align-items: flex-end;
}
.tip {
  line-height: 30px;
  margin-left: 20px;
  color: #666666;
  p {
    font-size: 12px;
    span {
      color: #409eff;
      cursor: pointer;
    }
  }
}
/deep/.el-upload--picture-card {
  width: 99px;
  height: 150px;
  i {
    font-size: 12px;
    color: #ffffff;
  }
}
/deep/.el-icon-plus:before {
  color: #666666;
}
.tooltips {
  width: 660px;
  height: 660px;
  img {
    width: 100%;
    height: 100%;
  }
}
.el-tooltip__popper.is-light {
  border: none !important;
  box-shadow: 0px 0px 5px 1px #e8e8e8;
}
</style>
<style>
.el-tooltip__popper[x-placement^='right-start'] .popper__arrow {
  border: none;
  box-shadow: 0px 0px 5px 1px #242323;
}
</style>
