<template>
  <div class="cont">
    <h2 class="part-title">支付记录</h2>
    <br />
    <el-form ref="form" :model="form" label-position="left" label-width="100px">
      <el-form-item label="创建日期">
        <el-input disabled v-model="form.created_at"></el-input>
      </el-form-item>
      <el-form-item label="订单号">
        <el-input disabled v-model="form.numbers"></el-input>
      </el-form-item>
      <el-form-item label="付费模块">
        <el-input disabled v-model="form.name"></el-input>
      </el-form-item>
      <el-form-item label="支付金额">
        <el-input disabled v-model="form.pay"></el-input>
      </el-form-item>
      <el-form-item label="支付日期">
        <el-input disabled v-model="form.pay_time"></el-input>
      </el-form-item>
      <el-form-item label="备注">
        <el-input disabled v-model="form.remarks"></el-input>
      </el-form-item>
      <el-form-item label="支付方式">
        <el-select disabled v-model="form.pay_type">
          <el-option
            v-for="item in options"
            :key="item.value"
            :label="item.label"
            :value="item.value"
          >
          </el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="交易流水号">
        <el-input disabled v-model="form.trade_no"></el-input>
      </el-form-item>
    </el-form>
  </div>
</template>
<script>
export default {
  data() {
    return {
      options: [
        {
          value: 1,
          label: '微信公众号支付',
        },
        {
          value: 2,
          label: '微信扫码支付',
        },
        {
          value: 3,
          label: '支付宝扫码支付',
        },
        {
          value: 20,
          label: '兑换码支付',
        },
      ],
    }
  },
  mounted() {},
  methods: {},
  props: ['form'],
}
</script>
