<template>
  <el-upload
    class="upload-demo"
    :action="url"
    :on-preview="handlePreview"
    :on-success="handleSuccess"
    :on-remove="handleRemove"
    :before-remove="beforeRemove"
    multiple
    :limit="3"
    :on-exceed="handleExceed"
    :file-list="fileList"
    :headers="header"
    accept=".png,.jpg,.jpeg,.doc,.pdf,.xls,.docx,.xlsx,.rar,.zip"
  >
    <el-button size="small" type="danger" class="upload">点击上传</el-button>
  </el-upload>
</template>
<script>
export default {
  props: ['fileList'],
  data() {
    return {
      url: '',
      header: {},
    }
  },
  created() {
    this.url = process.env.VUE_APP_URL + 'upload'
    this.header.Authorization = 'Bearer ' + this.Storage.getStorage('token')
  },
  methods: {
    handleRemove(file, fileList) {
      this.$emit('getList', fileList)
    },
    handlePreview(file) {
      var a = document.createElement('a')
      var event = new MouseEvent('click')
      a.download = file.name
      a.href = 'http://api.wangpeishi.org.cn' + file.url
      a.dispatchEvent(event)
    },
    handleExceed(files, fileList) {
      this.$message.warning(
        `当前限制选择 3 个文件，本次选择了 ${
          files.length
        } 个文件，共选择了 ${files.length + fileList.length} 个文件`
      )
    },
    beforeRemove(file) {
      return this.$confirm(`确定移除 ${file.name}？`)
    },
    handleSuccess(response, file, fileList) {
      console.log(response)
      console.log(file)
      this.$emit('getList', fileList)
    },
  },
}
</script>
<style lang="less" scoped>
.upload {
  background: #e10133;
  width: 100px;
  color: #fff;
}
</style>
