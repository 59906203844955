<template>
  <div class="comp">
    <el-upload
      :action="url"
      list-type="picture-card"
      accept=".png,.jpg,.jpeg"
      :on-success="handleSuccess"
      :before-upload="handlebefore"
      :headers="header"
      :show-file-list="false"
    >
      <i slot="default" v-show="updisplay" class="el-icon-plus"></i>
      <hovermask>
        <img
          :src="imgurl"
          v-show="!updisplay"
          @click="upload"
          class="el-upload el-upload--picture-card"
        />
        <template v-slot:action>
          <i class="el-icon-refresh-left">重新上传</i>
        </template>
      </hovermask>
    </el-upload>
    <div class="tip">
      <p>证书照片要求：</p>
      <p>1.证书照片应是本人的教师资格证书；</p>
      <p>2.请保证照片清晰显示证书内容；</p>
      <p>3.文件大小不得超过500K；</p>
    </div>
  </div>
</template>
<script>
import hovermask from './hovermask'
export default {
  props: ['fileUrl'],
  data() {
    return {
      url: '',
      file: '',
      header: {},
      dialogImageUrl: '',
      dialogVisible: false,
      disabled: false,
      updisplay: true,
      imgurl: '',
    }
  },
  components: {
    hovermask,
  },
  created() {
    this.url = process.env.VUE_APP_URL + 'upload'
    if (this.fileUrl || this.file.response) {
      this.imgurl =
        'http://api.wangpeishi.org.cn' +
          this.fileUrl +
          '?' +
          Math.ceil(Math.random() * 10000) ||
        'http://api.wangpeishi.org.cn' +
          this.file.response.data +
          '?' +
          Math.ceil(Math.random() * 10000)
    }
    this.header.Authorization = 'Bearer ' + this.Storage.getStorage('token')
    if (this.fileUrl != '' && this.fileUrl != null) {
      this.updisplay = false
    } else {
      this.updisplay = true
    }
  },
  methods: {
    handleSuccess(response, file, fileList) {
      console.log(response, file, fileList)
      this.imgurl = file.url
      this.file = file
      this.updisplay = false
      this.$emit('getnumber_img', file)
    },
    handlebefore(file) {
      this.updisplay = false
      var testmsg = file.name.substring(file.name.lastIndexOf('.') + 1)

      if (file.size / 1024 > 500) {
        this.$message.error('文件大小不能超过500k')
        return false
      } else {
        if (testmsg != 'png' && testmsg != 'jpg' && testmsg != 'jpeg') {
          this.$message.error('只能上传png，jpg，jpeg类型文件')
          return false
        }
      }
    },
    upload() {},
  },
}
</script>
<style lang="less" scoped>
.comp {
  display: flex;
  align-items: flex-end;
}
.tip {
  line-height: 30px;
  margin-left: 20px;
  color: #666666;
}
/deep/.el-upload--picture-card {
  width: 240px;
  height: 150px;
  i {
    font-size: 12px;
    color: #ffffff;
  }
}
/deep/.el-icon-plus:before {
  color: #666666;
}
</style>
