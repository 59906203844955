<template>
  <div class="box">
    <!-- 个人资料 -->
    <div class="part" v-show="show == 'info'">
      <!-- <h2 class="part-title">完善资料</h2> -->
      <el-form
        :model="form"
        label-position="right"
        label-width="100px"
        :rules="userRules"
        ref="userRuleForm"
      >
        <el-tabs v-model="activecont">
          <el-tab-pane label="个人资料" name="leftcont">
            <p class="warnInfo">
              提示：请务必填写个人真实信息，“姓名”、“性别”和“身份证号”一但认证后将无法更改！
              <el-tooltip
                class="item"
                effect="light"
                content="Bottom Right 提示文字"
                placement="bottom"
              >
                <div slot="content" class="tooltips">
                  <p>
                    1.考试需要实名认证考生的“姓名”及“身份证号”，不通过将无法参加考试。
                  </p>
                  <p>
                    2.考试通过后，网培师中心将根据用户的信息来制作证书，请如实填写信息，以防影响证书的制作。
                  </p>
                </div>
                <div>
                  <img src="../assets/images/user/wenhao.png" alt="" />
                </div>
              </el-tooltip>
            </p>
            <!-- <div class="cont"> -->
            <!-- <div class="leftcont">
                <el-form-item label="真实姓名：" prop="name">
                  <el-input
                    v-model="form.name"
                    :disabled="form.is_verfy"
                  ></el-input>
                </el-form-item>

                <el-form-item label="性别：" prop="sex">
                  <el-radio-group v-model="form.sex">
                    <el-radio label="1">男</el-radio>
                    <el-radio label="0">女</el-radio>
                  </el-radio-group>
                </el-form-item>

                <el-form-item label="身份证号：" prop="idcard">
                  <el-input
                    type="text"
                    v-model="form.idcard"
                    :disabled="form.is_verfy"
                    placeholder="请输入您的身份证证件号"
                  ></el-input>
                </el-form-item>

                <el-form-item label="教师资格证：" class="spe-item">
                  <el-input
                    type="text"
                    v-model="form.cert_number"
                    placeholder="请输入您的教师资格证"
                  ></el-input>
                </el-form-item> -->

            <!-- <el-form-item label="电子邮箱：" prop="email">
                  <el-input v-model="form.email" type="email" placeholder="请输入电子邮箱"></el-input>
                </el-form-item> -->

            <!-- <el-form-item label="现有学历：" prop="education">
                  <el-input
                    v-model="form.education"
                    placeholder="请输入现有学历"
                  ></el-input>
                </el-form-item>

                <el-form-item label="上传证件照片:">
                  <UploadIDPhoto
                    :fileUrl="form.cert_photo"
                    @getPhoto="getphoto"
                  />
                </el-form-item>
              </div>

              <div class="rightcont">
                <el-form-item label="所在城市：" prop="city">
                  <el-input
                    v-model="form.city"
                    type="text"
                    placeholder="请输入所在城市"
                  ></el-input>
                </el-form-item>

                <el-form-item label="收件地址：" prop="address">
                  <el-input
                    v-model="form.address"
                    type="text"
                    placeholder="请输入您的收件地址："
                  ></el-input>
                </el-form-item>

                <el-form-item label="所在行业：">
                  <el-input
                    v-model="form.industry"
                    type="text"
                    placeholder="请输入您所在的行业"
                  ></el-input>
                </el-form-item>

                <el-form-item label="从事工作：">
                  <el-input
                    v-model="form.work"
                    type="text"
                    placeholder="请输入您所从事的工作："
                  ></el-input>
                </el-form-item>

                <el-form-item label="推荐人编号：">
                  <el-input
                    type="text"
                    v-model="form.agent"
                    :disabled="readonly"
                    placeholder="请输入四位数字推荐人编号（如无，请勿填写）"
                  ></el-input>
                </el-form-item>

                <el-form-item label="上传教师资格证证件：" class="spe-item">
                  <UploadQCert
                    :fileUrl="form.cert_number_img"
                    @getnumber_img="getnumber_img"
                  />
                </el-form-item>
              </div> -->
            <!-- </div> -->

            <el-form-item label="真实姓名：" prop="name">
              <el-input
                v-model="form.name"
                :disabled="form.is_verfy"
              ></el-input>
            </el-form-item>

            <el-form-item label="性别：" prop="sex">
              <el-radio-group v-model="form.sex">
                <el-radio label="1">男</el-radio>
                <el-radio label="0">女</el-radio>
              </el-radio-group>
            </el-form-item>

            <el-form-item label="身份证号：" prop="idcard">
              <el-input
                type="text"
                v-model="form.idcard"
                :disabled="form.is_verfy"
                @paste.native.capture.prevent="
                  () => {
                    return false
                  }
                "
                placeholder="请输入您的身份证证件号"
              ></el-input>
            </el-form-item>

            <el-form-item label="现有学历：" prop="education">
              <el-input
                v-model="form.education"
                placeholder="请输入现有学历"
              ></el-input>
            </el-form-item>

            <el-form-item label="所在城市：" prop="city">
              <el-input
                v-model="form.city"
                type="text"
                placeholder="请输入所在城市"
              ></el-input>
            </el-form-item>

            <el-form-item label="所在行业：">
              <el-input
                v-model="form.industry"
                type="text"
                placeholder="请输入您所在的行业"
              ></el-input>
            </el-form-item>

            <el-form-item label="从事工作：">
              <el-input
                v-model="form.work"
                type="text"
                placeholder="请输入您所从事的工作："
              ></el-input>
            </el-form-item>

            <el-form-item label="推荐人编号：">
              <el-input
                type="text"
                v-model="form.agent"
                :disabled="readonly"
                placeholder="请输入四位数字推荐人编号（如无，请勿填写）"
              ></el-input>
            </el-form-item>

            <el-form-item label="上传证件照片:" class="spe-item">
              <UploadIDPhoto :fileUrl="form.cert_photo" @getPhoto="getphoto" />
            </el-form-item>

            <el-form-item label="收件地址：" prop="address">
              <el-input
                v-model="form.address"
                type="text"
                placeholder="请输入您的收件地址："
              ></el-input>
            </el-form-item>
          </el-tab-pane>

          <el-tab-pane label="辅助资料" name="rightcont" class="rightpane">
            <el-form-item label="上传教师资格证证件：" class="spe-item">
              <UploadQCert
                :fileUrl="form.cert_number_img"
                @getnumber_img="getnumber_img"
              />
            </el-form-item>

            <el-form-item label="教师资格证书编码：">
              <el-input
                type="text"
                v-model="form.cert_number"
                placeholder="请输入您的教师资格证书编码"
              ></el-input>
            </el-form-item>

            <el-form-item label="报考原因：（可多选） " class="spe-item">
              <el-checkbox-group v-model="form.objective">
                <el-checkbox
                  v-for="item in res"
                  :label="item"
                  :key="item"
                ></el-checkbox>
              </el-checkbox-group>
            </el-form-item>

            <el-form-item label="教学方式：（可多选）" class="spe-item">
              <el-checkbox-group v-model="form.way">
                <el-checkbox
                  v-for="item in methods"
                  :label="item"
                  :key="item"
                ></el-checkbox>
              </el-checkbox-group>
            </el-form-item>

            <el-form-item label="网络教学背景：" class="spe-item">
              <el-input
                type="textarea"
                v-model="form.background"
                placeholder="网络教学背景：简要介绍本人在网络培训教育中擅长的教学课程"
              ></el-input>
            </el-form-item>

            <el-form-item label="上传教师个人简历：" class="up_item">
              <upload :fileList="form.resume" @getList="getResume"></upload>
            </el-form-item>

            <el-form-item label="是否接受岗位推荐：" class="spe-item">
              <div class="waring">
                <el-radio-group v-model="form.is_recommend">
                  <el-radio :label="1">接受</el-radio>
                  <el-radio :label="0">不接受</el-radio>
                </el-radio-group>
                <el-tooltip
                  class="item"
                  effect="light"
                  content="Bottom Right 提示文字"
                  placement="bottom"
                >
                  <div slot="content" class="tooltips">
                    <p>
                      1.接受“岗位推荐”后，我们将根据您上传的个人简历帮您挑选符合您的岗位。
                    </p>
                    <p>
                      2.您接受岗位推荐即代表您允许平台将您的信息推荐给招聘方。
                    </p>
                  </div>
                  <div>
                    <img src="../assets/images/user/wenhao.png" alt="" />
                  </div>
                </el-tooltip>
              </div>
            </el-form-item>

            <el-form-item
              label="申请进入联合国训研所的官方人才库："
              class="spe-item"
            >
              <el-button
                class="apply is_un"
                @click="opun"
                v-if="form.is_un == 0"
                >申请</el-button
              >
              <p v-if="form.is_un == 1" style="color:#67C23A">已提交申请</p>
            </el-form-item>
          </el-tab-pane>
        </el-tabs>

        <el-form-item class="subbtn">
          <el-button type="danger" @click="onSubmit">保存</el-button>
        </el-form-item>
      </el-form>
    </div>
    <!-- 修改密码 -->

    <div class="part" v-show="show == 'resetpassword'">
      <h2 class="part-title">修改密码</h2>
      <el-form
        ref="form"
        :model="resetpass"
        label-position="left"
        label-width="130px"
        :rules="rules"
      >
        <el-form-item label="手机号:">
          <el-input v-model="form.mobile" disabled></el-input>
        </el-form-item>
        <!-- <el-form-item label="验证码:" prop="codes">
          <el-input
            v-model="resetpass.codes"
            class="code-box"
            placeholder="请输入验证码"
          ></el-input>
          <el-button @click="getCode(form.mobile, 'reset')" type="danger"
            >{{sendCon}}</el-button
          >
        </el-form-item> -->
        <el-form-item label="新密码：" prop="password">
          <el-input
            v-model="resetpass.password"
            type="password"
            placeholder="请输入新密码"
          ></el-input>
        </el-form-item>
        <el-form-item label="确认密码：" prop="rpassword">
          <el-input
            v-model="resetpass.rpassword"
            type="password"
            placeholder="请确认新密码"
          ></el-input>
        </el-form-item>
        <el-form-item>
          <el-button type="danger" @click="reset('form')" :disabled="isDisabled"
            >保存</el-button
          >
        </el-form-item>
      </el-form>
    </div>
    <!-- 修改手机号 -->
    <div class="part" v-show="show == 'resetmobile'">
      <h2 class="part-title">修改手机号</h2>
      <div class="flex">
        <span>当前手机号：</span>
        <h2 class="part-title">{{ form.mobile }}</h2>
      </div>
      <el-form :model="mobileForm" label-position="left" label-width="100px">
        <el-form-item label="新手机号码：">
          <el-input
            v-model="mobileForm.mobile"
            class="code-box"
            placeholder="请输入新手机号"
          ></el-input>
          <el-button
            type="danger"
            :disabled="isClick"
            @click="getCode(mobileForm.mobile, 'update')"
            >{{ sendCon }}</el-button
          >
        </el-form-item>
        <el-form-item label="验证码：">
          <el-input
            v-model="mobileForm.codes"
            type="email"
            placeholder="请输入验证码"
          ></el-input>
        </el-form-item>
        <el-form-item>
          <el-button type="danger" @click="resetMobile">保存</el-button>
        </el-form-item>
      </el-form>
    </div>
    <!-- 预约时间 -->
    <div class="part" v-show="show == 'appointment'">
      <h2 class="part-title">预约时间</h2>
      <el-table
        :data="appointmentData"
        style="width: 100%"
        :header-row-style="tableHead"
      >
        <el-table-column prop="name" label="姓名"></el-table-column>
        <el-table-column prop="date" label="预约考试日期"></el-table-column>
        <el-table-column prop="score" label="状态">
          <template>
            <el-link type="success">正常</el-link>
          </template>
        </el-table-column>
      </el-table>
    </div>
    <!-- 成绩 -->
    <div class="part" v-show="show == 'score'">
      <h2 class="part-title">评测成绩</h2>
      <el-table
        :data="tableData"
        style="width: 100%"
        :header-row-style="tableHead"
      >
        <el-table-column prop="name" label="考试科目"></el-table-column>
        <el-table-column prop="created_at" label="考试时间"></el-table-column>
        <el-table-column prop="score" label="成绩" width="100">
        </el-table-column>
      </el-table>
    </div>

    <!-- 证书 -->
    <div class="part" v-show="show == 'certs'">
      <h2 class="part-title">我的证书</h2>
      <el-table
        :data="certsList"
        style="width: 100%"
        :header-row-style="tableHead"
      >
        <el-table-column prop="name" label="证书名称">
          <template slot-scope="scope">
            <span v-if="scope.row.type == 0">初级网培师证书</span>
            <span v-if="scope.row.type == 1">中级网培师证书</span>
          </template>
        </el-table-column>
        <el-table-column prop="created_at" label="颁发日期"> </el-table-column>
        <el-table-column label="查看证书">
          <template slot-scope="scope">
            <el-link type="primary" @click="look(scope.row.cert)">查看</el-link>
          </template>
        </el-table-column>
      </el-table>
    </div>

    <!-- 申请高级网培师 -->
    <div class="part" v-show="show == 'apply'">
      <h2 class="part-title">高级网培师申请</h2>
      <p class="part-cont">
        高级网培师高级网培师申请满足条件：
        <br />① 已获得网培师证且已持证工作1年以上 <br />②
        上传不少于3个本人平时优秀教学视频作品 <br />③
        上传辅助材料如教师资格证、工作证明,个人简历、推荐信、教学奖章等
        <br />若已满足上述要求,请在下方填写基本资料,完成后点击申请按钮进行申请,发证方收到申请后,会不定期组织教育联盟评审专家团队对已上传的资料进行评审,必要时会发起远程视频进行在线答疑。
      </p>
      <br />
      <br />
      <h2 class="part-title">个人作品上传</h2>
      <el-form :data="work" style="width: 100%" :header-row-style="tableHead">
        <el-form-item label="作品名称：">
          <el-input v-model="work.name"></el-input>
        </el-form-item>
        <el-form-item label="作品分类：">
          <el-select v-model="work.type" placeholder="请选择">
            <el-option
              v-for="item in options"
              :key="item"
              :label="item"
              :value="item"
            ></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="内容简介：">
          <el-input v-model="work.content" type="textarea"></el-input>
        </el-form-item>
        <el-form-item label="作品上传：">
          <uploadVedio @getlist="getworkurl"></uploadVedio>
        </el-form-item>

        <el-form-item>
          <el-button type="danger" @click="toApply">提交申请</el-button>
        </el-form-item>
      </el-form>
    </div>
    <!-- 上传个人作品 -->
    <div class="part" v-show="show == 'works'">
      <h2 class="part-title">个人作品上传</h2>

      <el-form
        ref="form"
        :model="form"
        label-position="left"
        label-width="100px"
      >
        <el-form-item label="作品名称：">
          <el-input v-model="form.name"></el-input>
        </el-form-item>
        <el-form-item label="作品分类：">
          <el-select v-model="form.region" placeholder="请选择">
            <el-option
              v-for="item in options"
              :key="item"
              :label="item"
              :value="item"
            ></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="内容简介：">
          <el-input v-model="form.email" type="email"></el-input>
        </el-form-item>
        <el-form-item label="作品上传：" class="spe-item">
          <p>提示：上传附件格式为。MP4视频文件，大小不超过200M</p>
        </el-form-item>
        <el-form-item>
          <el-button type="danger" @click="onSubmit">提交</el-button>
        </el-form-item>
      </el-form>
    </div>
    <!-- 个人作品列表 -->
    <div class="part" v-show="show == 'workslist'">
      <h2 class="part-title">个人上传作品列表</h2>

      <el-table
        :data="workList"
        style="width: 100%"
        :header-row-style="tableHead"
      >
        <el-table-column label="作品名称" prop="name"></el-table-column>
        <el-table-column label="上传时间" prop="updated_at"></el-table-column>
        <el-table-column label="类别">
          <template slot-scope="scope">
            <span v-if="scope.row.class_type.indexOf('[') < 0">{{
              scope.row.class_type
            }}</span>
            <span
              v-else
              v-for="item in JSON.parse(scope.row.class_type)"
              :key="item"
              >{{ item + ' ' }}</span
            >
          </template>
        </el-table-column>
        <el-table-column label="状态">
          <template slot-scope="scope">
            <el-link v-if="scope.row.status == 1" type="success"
              >已审核</el-link
            >
            <el-link v-if="scope.row.status == 0" type="warning"
              >审核中</el-link
            >
            <el-link v-if="scope.row.status == 2" type="danger"
              >审核未通过</el-link
            >
          </template>
        </el-table-column>
        <el-table-column label="操作">
          <template slot-scope="scope">
            <el-link
              type="primary"
              @click="workInfo(scope.row)"
              v-if="scope.row.status !== 1"
              >修改</el-link
            >
            <el-link type="primary" @click="lookInfo(scope.row)" v-else
              >查看</el-link
            >
            <b class="line">|</b>
            <el-link
              type="danger"
              @click="workInfoDel(scope.$index, scope.row.id)"
              >删除</el-link
            >
          </template>
        </el-table-column>
      </el-table>
    </div>
    <!-- 支付记录 -->
    <div class="part" v-show="show == 'paylist'">
      <h2 class="part-title">我的支付记录</h2>

      <el-table
        :data="orderList"
        style="width: 100%"
        :header-row-style="tableHead"
      >
        <el-table-column
          label="订单号"
          prop="numbers"
          width="140"
        ></el-table-column>
        <el-table-column
          label="付费模块"
          prop="name"
          width="100"
        ></el-table-column>
        <el-table-column
          prop="pay_time"
          label="支付日期"
          width="100"
        ></el-table-column>
        <el-table-column label="支付金额" prop="pay"></el-table-column>
        <el-table-column label="支付状态" prop="pay_status">
          <template slot-scope="scope">
            <span v-if="scope.row.pay_status == 1">交易成功</span>
            <span v-if="scope.row.pay_status == 2">交易失败</span>
          </template>
        </el-table-column>

        <el-table-column label="申请发票" prop="name">
          <template slot-scope="scope">
            <el-link @click="applyFP(scope.row)" class="look">申请</el-link>
          </template>
        </el-table-column>
        <el-table-column label="详情" prop="trade_no">
          <template slot-scope="scope">
            <el-link @click="viewClick(scope.row)" type="primary">查看</el-link>
          </template>
        </el-table-column>
      </el-table>
    </div>
    <!-- 申请发票弹框 -->
    <el-dialog title="申请开票" :visible.sync="showfp" width="500px">
      <el-form
        ref="fpForm"
        :rules="fpRules"
        :model="form_fp"
        label-width="100px"
      >
        <el-form-item label="发票抬头：" prop="name">
          <el-input
            v-model="form_fp.name"
            placeholder="请输入发票抬头"
          ></el-input>
        </el-form-item>
        <el-form-item label="税号：" prop="numbers">
          <el-input
            v-model="form_fp.numbers"
            placeholder="请输入税号"
          ></el-input>
        </el-form-item>
        <el-form-item label="联系人：" prop="user_name">
          <el-input
            v-model="form_fp.user_name"
            placeholder="请输入联系人"
          ></el-input>
        </el-form-item>
        <el-form-item label="手机号：" prop="mobile">
          <el-input
            v-model="form_fp.mobile"
            placeholder="请输入手机号"
          ></el-input>
        </el-form-item>
        <el-form-item label="地址：" prop="address">
          <el-input
            v-model="form_fp.address"
            placeholder="请输入详细地址"
          ></el-input>
        </el-form-item>
        <el-form-item label="订单号" prop="order_numbers">
          <el-input
            v-model="form_fp.order_numbers"
            placeholder="请输入订单号"
          ></el-input>
        </el-form-item>
        <el-form-item label="开票金额：" prop="pay">
          <!-- <el-input-number v-model="form_fp.pay" :min="1"></el-input-number> -->
          <el-input
            v-model="form_fp.pay"
            placeholder="请输入开票金额"
            oninput="value=value.replace(/[^\d]/g,'')"
          ></el-input>
        </el-form-item>
        <div class="text-center">
          <el-button type="danger" @click="tokp" :disabled="isDisabled"
            >提交</el-button
          >
        </div>
      </el-form>
    </el-dialog>
    <el-dialog title="查看证书" :visible.sync="showCert" width="500px">
      <img :src="'http://api.wangpeishi.org.cn/' + cert_url" alt="" />
    </el-dialog>
    <!-- 修改作品 -->
    <el-dialog :visible.sync="workInfoShow" width="500px">
      <uploadWork
        :type="userCenUrlType"
        :parForm="parForm"
        @getlist="getlist"
      ></uploadWork>
    </el-dialog>
    <!-- 查看作品 -->
    <el-dialog :visible.sync="lookInfoShow" width="500px">
      <uploadWork
        :look="userType"
        :parForm="parForm"
        @getlist="getlist"
      ></uploadWork>
    </el-dialog>
    <!-- 订单查看 -->
    <el-dialog :visible.sync="viewWorkShow" width="400px">
      <viewWorkDig :form="viewWorkData"></viewWorkDig>
    </el-dialog>
    <el-dialog title="温馨提示" :visible.sync="workInfoDelShow" width="30%">
      <span>确定要删除吗？</span>
      <span slot="footer" class="dialog-footer">
        <el-button @click="workInfoDelShow = false">取 消</el-button>
        <el-button
          type="primary"
          @click="workInfoShowDel"
          :disabled="isDisabled"
          >确 定</el-button
        >
      </span>
    </el-dialog>
  </div>
</template>

<script>
// @ is an alias to /src
let _this
import Upload from '../components/upload'
import UploadVedio from '../components/uploadVedio'
import UploadWork from '../components/uploadWork'
import viewWorkDig from '../components/viewWorkDig'
import UploadIDPhoto from '../components/UploadIDPhoto'
import UploadQCert from '../components/UploadQCert'
export default {
  name: 'UserCenter',
  components: {
    Upload,
    UploadVedio,
    UploadWork,
    viewWorkDig,
    UploadIDPhoto,
    UploadQCert,
  },
  data() {
    var validatePass = (rule, value, callback) => {
      if (value == '') {
        callback(new Error('请输入密码'))
      } else {
        if (this.resetpass.rpassword !== '') {
          this.$refs.form.validateField('rpassword')
        }
        callback()
      }
    }
    var validatePass2 = (rule, value, callback) => {
      if (value == '') {
        callback(new Error('请再次输入密码'))
      } else if (value !== this.resetpass.password) {
        callback(new Error('两次输入密码不一致!'))
      } else {
        callback()
      }
    }
    var validatePay = (rule, value, callback) => {
      if (value === '') {
        callback(new Error('请输入开票金额'))
      } else {
        if (value != JSON.parse(this.pay)) {
          callback(new Error('开票金额不能大于支付金额'))
        } else {
          callback()
        }
      }
    }
    return {
      userRules: {
        name: {
          required: true,
          message: '请输入姓名',
          trigger: 'blur',
        },
        sex: {
          required: true,
          message: '请选择性别',
          trigger: 'blur',
        },
        email: [
          {
            required: true,
            message: '请输入电子邮箱',
            trigger: 'blur',
          },
          {
            pattern: /^([a-zA-Z0-9_-])+@([a-zA-Z0-9_-])+(.[a-zA-Z0-9_-])+/,
            message: '电子邮箱格式不正确!',
            trigger: 'blur',
          },
        ],
        education: {
          required: true,
          message: '请输入学历信息',
          trigger: 'blur',
        },
        city: {
          required: true,
          message: '请输入所在城市',
          trigger: 'blur',
        },
        address: {
          required: true,
          message: '请输入地址',
          trigger: 'blur',
        },
        idcard: [
          { required: true, message: '请输入身份证号', trigger: 'blur' },
          {
            pattern: /^([1-6][1-9]|50)\d{4}(18|19|20)\d{2}((0[1-9])|10|11|12)(([0-2][1-9])|10|20|30|31)\d{3}[0-9Xx]$/,
            message: '身份证号格式不正确!',
            trigger: 'blur',
          },
        ],
      },
      userCenUrlType: '1',
      userType: '2',
      workInfoShow: false,
      lookInfoShow: false,
      viewWorkShow: false,
      workInfoDelShow: false,
      viewWorkData: '',
      parForm: '',
      show: '',
      timer: null,
      count: '',
      sendCon: '获取验证码',
      isClick: false,
      setPhoneType: '',
      showfp: false,
      showCert: false,
      cert_url: '',
      form_fp: {
        name: '',
        numbers: '',
        user_name: '',
        mobile: '',
        address: '',
        pay: '',
        order_numbers: '',
      }, //开票
      fpRules: {
        name: {
          required: true,
          message: '请输入发票抬头',
          trigger: ['blur', 'change'],
        },
        numbers: {
          required: true,
          message: '请输入税号',
          trigger: ['blur', 'change'],
        },
        user_name: {
          required: true,
          message: '请输入联系人',
          trigger: ['blur', 'change'],
        },
        mobile: [
          { required: true, message: '请输入手机号', trigger: 'blur' },
          {
            pattern: /^1[34578]\d{9}$/,
            message: '请输入正确的手机号',
            trigger: 'blur',
          },
        ],
        address: {
          required: true,
          message: '请输入详细地址',
          trigger: ['blur', 'change'],
        },
        pay: {
          required: true,
          validator: validatePay,
          trigger: ['blur', 'change'],
        },
        order_numbers: {
          required: true,
          message: '请输入订单号',
          trigger: ['blur', 'change'],
        },
      },
      form: {},
      resetpass: {
        mobile: '',
        // codes: "",
        password: '',
        rpassword: '',
      },
      work: [],
      mobileForm: {},
      tableData: [],
      orderList: [],
      workList: [],
      appointmentData: [],
      tableHead: {
        backgroundColor: '#CE9A6C',
        color: '#fff',
      },
      options: [
        '语文',
        '数学',
        '英语',
        '物理',
        '化学',
        '生物',
        '政治',
        '历史',
        '地理',
        '幼儿教育',
        '语言',
        '职业技能',
        'IT/设计培训',
        '文艺/体育培训',
        '学历教育',
        '管理培训',
        '其他',
      ],
      res: [
        '个人能力提升',
        '职业背景提升',
        '应企业要求',
        '兴趣爱好',
        '打算进入网培行业',
        '其它',
      ],
      methods: ['音视频直播', '音视频录播', '一对一远程辅导', '其它'],
      rules: {
        password: { required: true, validator: validatePass, trigger: 'blur' },
        // codes: { required: true, message: "请输入验证码", trigger: "blur" },
        rpassword: {
          required: true,
          validator: validatePass2,
          trigger: 'blur',
        },
      },
      certsList: [],
      workInfoId: '',
      isDisabled: false,
      loading: false,
      numbersId: '',
      readonly: false,
      activecont: 'leftcont',
      pay: '',
    }
  },
  created() {
    this.init()
    this.agentstore()
    this.show = this.$route.params.id
    switch (this.show) {
      case 'workslist':
        this.getWorkList()
        break
      case 'paylist':
        this.getOrderList()
        break
      case 'certs':
        this.getCert()
        break
      case 'score':
        this.getScore()
        break
      case 'appointment':
        this.getAppointment()
        break
      default:
        break
    }
  },
  watch: {
    $route() {
      this.show = this.$route.params.id
      switch (this.show) {
        case 'workslist':
          this.getWorkList()
          break
        case 'paylist':
          this.getOrderList()
          break
        case 'certs':
          this.getCert()
          break
        case 'score':
          this.getScore()
          break
        case 'appointment':
          this.getAppointment()
          break
        default:
          break
      }
    },
  },
  methods: {
    //处理form数据
    init() {
      _this = this
      _this.show = this.$route.params.id
      _this.form = this.$store.state.wps_user
      //个人简历处理
      _this.form.resume = this.process(this.form.resume)
      //资格证证件处理
      // _this.form.cert_number_img = this.process(this.form.cert_number_img)
      //报考原因
      _this.form.objective = this.process(this.form.objective)
      //教学方式
      _this.form.way = this.process(this.form.way)

      _this.form.sex = _this.form.sex.toString()
      _this.form.is_verfy = _this.form.is_verfy == 1 ? true : false
    },
    //表单加载数据处理
    process(val) {
      if (val) {
        var type = typeof val
        switch (type) {
          case 'string':
            val = JSON.parse(val)
            break
          case 'boolean':
            val = []
            break
        }
        return val
      } else {
        return []
      }
    },
    //推荐人状态
    agentstore() {
      if (_this.form.agent == '' || _this.form.agent == null) {
        _this.$http
          .post(process.env.VUE_APP_URL + 'isPay', { good_id: 1 })
          .then(data => {
            if (data.data.code == 201 && data.data.token != '') {
              this.readonly = true
            } else {
              this.readonly = false
            }
          })
          .catch(err => {
            console.log(err)
          })
      } else {
        this.readonly = true
      }
    },
    //完善 信息
    onSubmit(isun) {
      let form = JSON.parse(JSON.stringify(this.form))
      form.resume = JSON.stringify(form.resume)
      // form.cert_number_img = JSON.stringify(form.cert_number_img)
      form.objective = JSON.stringify(form.objective)
      form.way = JSON.stringify(form.way)
      this.$refs.userRuleForm.validate(valid => {
        if (valid) {
          _this.$http
            .post(process.env.VUE_APP_URL + 'user', form)
            .then(data => {
              if (data.data.code == 200) {
                if (isun == 'un') {
                  _this.$message.success('已经提交申请')
                } else {
                  _this.$message.success('保存成功')
                }
                _this.$store.commit('setUser', this.form)
                _this.init()
                _this.loading = false
              } else {
                _this.$message.error(data.data.msg)
                _this.loading = false
              }
            })
            .catch(err => {
              _this.loading = false
              console.log(err)
            })
        } else {
          _this.$message.error('请填写完必填信息')
          _this.activecont = 'leftcont'
          return false
        }
      })
    },
    viewClick(row) {
      this.viewWorkShow = true
      this.viewWorkData = row
    },
    look(cert) {
      this.cert_url = cert
      this.showCert = true
    },
    //获取验证码
    getCode(mobile, type) {
      let params = {
        type: type,
        mobile: mobile,
      }
      this.setPhoneType = type
      if (/^1[3456789]\d{9}$/.test(mobile)) {
        _this.$http
          .post(process.env.VUE_APP_URL + 'sendCode', params)
          .then(data => {
            if (data.data.code == 200) {
              this.countdown()
              _this.$message.success('验证码已发送')
            } else {
              _this.$message.error(data.data.msg)
            }
          })
          .catch(err => {
            _this.$message.error('网络错误，请重试')

            console.log(err)
          })
      } else {
        this.$message.warning('请输入正确格式的手机号')
        return
      }
    },
    countdown() {
      const TIME_COUNT = 60
      if (!this.timer) {
        this.count = TIME_COUNT
        this.timer = setInterval(() => {
          if (this.count > 0 && this.count <= TIME_COUNT) {
            this.isClick = true
            this.count--
            this.sendCon = this.count + '秒后再次获取'
          } else {
            this.isClick = false
            clearInterval(this.timer)
            this.timer = null
            this.sendCon = '获取验证码'
          }
        }, 1000)
      }
    },
    //密码重置
    reset(formName) {
      _this.isDisabled = true
      this.$refs[formName].validate(valid => {
        this.resetpass.mobile = this.form.mobile
        if (valid) {
          _this.$http
            .post(process.env.VUE_APP_URL + 'updatePassword', this.resetpass)
            .then(data => {
              if (data.data.code == 200) {
                _this.$message.success('修改成功')
                _this.isDisabled = false
                this.resetpass.rpassword = ''
                this.resetpass.password = ''
              } else {
                _this.$message.error(data.data.msg)
                _this.isDisabled = false
              }
            })
            .catch(err => {
              _this.$message.error('网络错误，请重试')
              console.log(err)
              _this.isDisabled = false
            })
        } else {
          return false
        }
      })
    },
    //手机号修改
    resetMobile() {
      let mobile = this.mobileForm.mobile
      if (/^1[3456789]\d{9}$/.test(mobile)) {
        _this.$http
          .post(process.env.VUE_APP_URL + 'updateMobile', this.mobileForm)
          .then(data => {
            if (data.data.code == 200) {
              _this.$message.success('修改成功')
              _this.form.mobile = this.mobileForm.mobile
            } else {
              _this.$message.error(data.data.msg)
            }
          })
          .catch(err => {
            _this.$message.error('网络错误，请重试')
            console.log(err)
          })
      } else {
        this.$message.warning('请输入正确格式的手机号')
        return
      }
    },
    workInfo(row) {
      this.workInfoShow = true
      this.parForm = row
    },
    lookInfo(row) {
      this.lookInfoShow = true
      this.parForm = row
    },
    workInfoDel(index, id) {
      this.workInfoDelShow = true
      this.workInfoId = id
    },
    workInfoShowDel() {
      // let id = String(this.workInfoDelData)
      this.isDisabled = true
      _this.$http
        .delete(process.env.VUE_APP_URL + 'work' + '/' + this.workInfoId)
        .then(data => {
          if (data.data.code == 200) {
            this.workInfoDelShow = false
            this.isDisabled = false
            this.getWorkList()
          } else {
            _this.$message.error(data.data.msg)
            this.isDisabled = false
          }
        })
        .catch(err => {
          _this.$message.error('网络错误，请重试')
          console.log(err)
        })
    },
    getlist(data) {
      if (data == 200) {
        this.workInfoShow = false
      }
    },
    getWorkList() {
      _this.$http
        .get(process.env.VUE_APP_URL + 'work')
        .then(data => {
          if (data.data.code == 200) {
            _this.workList = data.data.data
          } else {
            _this.$message.error(data.data.msg)
          }
        })
        .catch(err => {
          _this.$message.error('网络错误，请重试')
          console.log(err)
        })
    },
    //预约时间
    getAppointment() {
      _this.$http
        .post(process.env.VUE_APP_URL + 'apply/userApply')
        .then(data => {
          if (data.data.code == 200) {
            _this.appointmentData = data.data.data
            _this.appointmentData.forEach(item => {
              item.name = this.$store.state.wps_user.name
            })
          } else {
            _this.$message.error(data.data.msg)
          }
        })
        .catch(err => {
          _this.$message.error('网络错误，请重试')
          console.log(err)
        })
    },

    getOrderList() {
      _this.$http
        .get(process.env.VUE_APP_URL + 'order')
        .then(data => {
          if (data.data.code == 200) {
            _this.orderList = data.data.data
          } else {
            _this.$message.error(data.data.msg)
          }
        })
        .catch(err => {
          _this.$message.error('网络错误，请重试')
          console.log(err)
        })
    },
    getList(list) {
      let arr = []
      list.forEach(item => {
        arr.push({
          name: item.name,
          url: item.url || item.response.data,
        })
      })
      this.form.cert_number_img = arr
    },
    getphoto(file) {
      this.form.cert_photo = file.response.data
    },
    getnumber_img(file) {
      this.form.cert_number_img = file.response.data
    },
    getResume(list) {
      let arr = []
      list.forEach(item => {
        arr.push({
          name: item.name,
          url: item.url || item.response.data,
        })
      })
      this.form.resume = arr
    },
    applyFP(row) {
      this.form_fp.order_numbers = row.numbers
      this.pay = row.pay
      this.showfp = true
    },
    tokp() {
      this.$refs.fpForm.validate(valid => {
        if (valid) {
          _this.$http
            .post(process.env.VUE_APP_URL + 'invoice', this.form_fp)
            .then(data => {
              _this.form_fp = {
                name: '',
                numbers: '',
                user_name: '',
                mobile: '',
                address: '',
                pay: '',
                order_numbers: '',
              }
              if (data.data.code == 200) {
                _this.$message.success('申请成功')
                _this.getOrderList()
                _this.showfp = false
                this.isDisabled = false
              } else {
                _this.$message.error(data.data.msg)
                this.isDisabled = false
              }
            })
            .catch(err => {
              _this.$message.error('网络错误，请重试')
              console.log(err)
              _this.isDisabled = false
              _this.form_fp = {
                name: '',
                numbers: '',
                user_name: '',
                mobile: '',
                address: '',
                pay: '',
                order_numbers: '',
              }
            })
        } else {
          return false
        }
      })
    },
    getworkurl(url) {
      this.work.url = url
    },
    toApply() {
      this.$message.warning('您暂不具备申请高级网培师的资格')
    },
    getCert() {
      _this.$http
        .get(process.env.VUE_APP_URL + 'certsign')
        .then(data => {
          if (data.data.code == 200) {
            _this.getCertList(data.data.data)
          } else {
            _this.$message.error(data.data.msg)
          }
        })
        .catch(err => {
          _this.$message.error('网络错误，请重试')
          console.log(err)
        })
    },

    getScore() {
      _this.$http
        .get(process.env.VUE_APP_URL + 'result')
        .then(data => {
          if (data.data.code == 200) {
            _this.tableData = data.data.data
          } else {
            _this.$message.error(data.data.msg)
          }
        })
        .catch(err => {
          _this.$message.error('网络错误，请重试')
          console.log(err)
        })
    },
    getCertList() {
      // var formData = new FormData()
      // formData.append('mobile', params.mobile)
      _this.$http
        .get(process.env.VUE_APP_URL + 'userCert')
        .then(data => {
          if (data.data.code == 200) {
            _this.certsList = data.data.data
          } else {
            _this.$message.error(data.data.msg)
          }
        })
        .catch(err => {
          _this.$message.error('网络错误，请重试')
          console.log(err)
        })
    },
    opun() {
      this.$alert(
        '<p>1.获得中级网培师证书后，学员可申请进入联合国训研所的官方人才库。</p><p>2.申请需要缴纳500元手续费，发起后3个工作日内会有客服主动联系您，请保持手机畅通。</p>',
        '提示',
        {
          dangerouslyUseHTMLString: true,
          confirmButtonText: '发起申请',
          customClass: 'un',
          callback: action => {
            if (action == 'confirm') {
              this.form.is_un = 1
              this.onSubmit('un')
            } else {
              this.form.is_un = 0
            }
          },
        }
      )
    },
  },
}
</script>
<style lang="less" scoped>
.box {
  font-weight: normal;
  // min-height: 500px;
  /deep/.el-button--danger {
    background: #e10133;
    width: 150px;
    color: #fff;
  }
  img {
    width: 100%;
  }
  .big-img {
    width: 100%;
  }
  .part {
    width: 100%;
    position: relative;
    // padding-top: 30px;
    .el-form {
      .el-input,
      .el-select {
        width: 450px;
      }
      .code-box {
        width: 220px;
        margin-right: 20px;
      }
      /deep/.el-form-item {
        margin-bottom: 35px;
      }
      /deep/.el-form-item__label {
        color: #333;
        white-space: nowrap;
      }
      // /deep/.spe-item .el-form-item__content {
      //   margin-left: 150px !important;
      // }

      .cont {
        display: flex;
        justify-content: space-between;
        .leftcont {
          margin-left: 10px;
        }
      }
      .rightpane {
        padding-top: 10px;
        /deep/label.el-form-item__label {
          width: auto !important;
        }
        // /deep/.el-form-item__content{
        //     // margin-left: 150px!important;
        // }
        .apply {
          padding: 0px 10px;
          height: 32px;
          background-color: #960f23;
          color: #ffffff;
          font-size: 12px;
          text-decoration: none;
        }
      }
      /deep/.el-form-item.spe-item {
        .el-form-item__content {
          margin-left: 10px !important;
        }
        .el-form-item__label {
          float: none;
        }
      }
      /deep/.el-form-item.up_item {
        .el-form-item__content {
          margin-left: 150px !important;
        }
      }
      .subbtn {
        margin-top: 30px;
        text-align: center;
      }
      /deep/.el-tabs__item {
        color: #333;
        font-weight: bold;
        font-size: 18px;
      }
      /deep/.el-tabs__item.is-active {
        color: #960f23;
      }
      /deep/.el-tabs__active-bar {
        height: 3px;
        background-color: #960f23;
      }
    }
    // /deep/
    //   .el-form-item.is-required:not(.is-no-asterisk)
    //   > .el-form-item__label:before {
    //   content: '';
    //   width: 0px;
    //   margin-right: 0px;
    // }
    // /deep/
    //   .el-form-item.is-required:not(.is-no-asterisk)
    //   > .el-form-item__label::after {
    //   content: '*';
    //   color: red;
    // }
    /deep/.el-table th,
    .el-table tr {
      background: none;
      background: #960f23;
    }
    .look,
    .apply {
      text-decoration: underline;
    }
    .look {
      color: #2f8e00;
    }
    .apply {
      color: #4b8ccc;
    }
    .is_un {
      width: 100px;
    }
    .part-h3 {
      font-size: 25px;
      padding: 26px 0;
      text-align: center;
    }
    .part-title {
      color: #333;
      font-weight: bold;
      font-size: 18px;
      margin-bottom: 15px;
    }
    .part-cont {
      padding-top: 14px;
      color: #7c8087;
      line-height: 2;
    }
    .part-indent {
      text-indent: 20px;
    }
    .part-offset {
      padding-left: 20px;
      border-radius: 4px;
      background: #960f23;
      margin-top: 10px;
      .part-title {
        padding-bottom: 26px;
      }
      /deep/.el-card .el-card__body {
        padding: 40px 60px 85px 20px;
        padding-bottom: 85px;
        .biao {
          position: absolute;
          z-index: 1000;
          bottom: 10px;
          // width: 100%;
          img {
            width: 750px;
          }
        }
      }
    }
    .icon {
      width: 60px;
    }
    strong {
      color: #000005;
      padding: 30px;
      font-size: 25px;
    }
    .text-center {
      padding-bottom: 60px;
    }
  }
  .part-bg {
    width: 100%;
    background: url('../assets/images/home/part-bg.jpg');
    background-size: 100% 100%;
    .el-card {
      height: 560px;
      box-sizing: border-box;
      padding: 0 15px;
    }
  }
  .part-text {
    padding-left: 150px;
    padding-right: 150px;
    img {
      margin: 60px 0;
    }
  }

  .el-row {
    margin: 10px 0;
  }
  .line {
    color: #666;
    padding: 2px 5px 0 5px;
  }
  .warnInfo {
    padding: 10px 0px 30px 10px;
    color: red;
    display: flex;
    align-items: center;
    img,
    .el-tooltip {
      width: 20px;
      height: 20px;
      cursor: pointer;
    }
  }
  .waring {
    display: flex;
    align-items: center;
    line-height: 0px;
    img,
    .el-tooltip {
      margin-left: 20px;
      margin-bottom: 2px;
      width: 15px;
      height: 15px;
      cursor: pointer;
    }
  }
}
</style>
<style>
.tooltips p {
  text-align: left;
}
.el-tooltip__popper[x-placement^='bottom'] .popper__arrow {
  border: none;
  box-shadow: 0px 0px 5px 1px #242323;
}
.el-message-box.un {
  width: 300px;
}
.el-message-box.un .el-message-box__header {
  text-align: center;
}
.el-message-box.un .el-message-box__btns {
  text-align: center;
}
.el-message-box.un .el-message-box__btns .el-button {
  border: none;
  margin: 10px auto;
  padding: 0px 20px;
  height: 32px;
  background-color: #960f23;
  color: #ffffff;
  font-size: 12px;
}
</style>
